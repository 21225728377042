'use client';

import { forwardRef, useImperativeHandle, useRef } from 'react';

import { Content, Topic } from '@/lib/model';
import {
  getMediaIconType,
  getOptimizedImageFromCoverImage,
} from '@/common/utils';
import Image from 'next/image';
import {
  AudioPlayerWithTimer,
  PlayerWithTimerRef,
} from '../../player/PlayerWithTimer';

export type MediaCardWithTimerRef = {
  play: () => void;
  stop: () => void;
};

type Props = {
  content?: Content;
  isAudioCarousel?: boolean;
  hideDetailPageArrow?: boolean;
  hideTitle?: boolean;
};

export const MediaCard = forwardRef<MediaCardWithTimerRef, Props>(
  (
    { content, hideDetailPageArrow, isAudioCarousel = true, hideTitle },
    ref,
  ) => {
    const { title, description } = content || {};
    const audioRef = useRef<PlayerWithTimerRef | null>(null);
    useImperativeHandle(
      ref,
      () =>
        ({
          play: () => {
            if (audioRef.current) {
              audioRef.current?.play?.();
            }
          },
          stop: () => {
            if (audioRef.current) {
              audioRef.current?.stop?.();
            }
          },
        }) as MediaCardWithTimerRef,
    );

    const image = getOptimizedImageFromCoverImage(
      content?.coverImage,
      200,
      200,
    );

    const { isVideo, isAudio } = getMediaIconType(content);

    const primaryTopic = (content?.primaryTopics as Topic)?.title;

    return (
      <div className="grid gap-4">
        <div
          className="w-full h-full aspect-square rounded-custom overflow-hidden bg-no-repeat relative cursor-pointer show-mini-player video-container"
          data-title={title || ''}
          data-topic={primaryTopic || ''}
          data-description={description || ''}
          data-is-audio={isAudioCarousel}
          data-background={image}
          data-vjs-player
        >
          <Image
            src={image}
            alt={content?.title || ''}
            fill
            className="object-cover h-full w-full"
            quality={80}
          />
          <div
            onClick={() => {
              audioRef.current?.togglePlay();
            }}
            className="w-full h-full absolute bottom-0 bg-gradient-to-b -from-[0%] to-[100%] from-[#00000000] to-[#000000E5] rounded-custom"
          />

          <div className="absolute bottom-4 left-4 right-4 flex flex-col gap-4 z-10">
            {(isVideo || isAudio) && content ? (
              <AudioPlayerWithTimer
                content={content as Content}
                image={image}
                ref={audioRef}
                hideDetailPageArrow={hideDetailPageArrow}
              />
            ) : null}
          </div>
        </div>
        {!hideTitle ? (
          <div
            onClick={() => {
              audioRef.current?.togglePlay();
            }}
            className="font-playfair xl:text-xl xl:leading-7 lg:text-lg lg:leading-6 md:text-base md:leading-5.5 text-xl leading-7 font-semibold text-grey-12 line-clamp-4"
          >
            {title}
          </div>
        ) : null}
      </div>
    );
  },
);
