import { Author, ContentMetadata } from '@/lib/model';
import { getAuthorLinkWithId } from '@/utils/links/author';
import { LinkWithChannelLink } from '../../link/LinkWithChannel';

type Props = {
  mobileContentMetadata: ContentMetadata;
  desktopContentMetadata: ContentMetadata;
  prefix?: string;
  noLink?: boolean;
  limit?: number;
};

const AuthorRow = ({
  name,
  id,
  hasMoreAuthor,
  noLink,
}: {
  name: string;
  id: string;
  hasMoreAuthor?: boolean;
  noLink?: boolean;
}) => {
  return noLink ? (
    <span>{` ${name}${hasMoreAuthor ? ',' : ''}`}</span>
  ) : (
    <LinkWithChannelLink href={getAuthorLinkWithId(id || '')}>
      <span>{` ${name}${hasMoreAuthor ? ',' : ''}`}</span>
    </LinkWithChannelLink>
  );
};

function RenderAuthor({
  mobileContentMetadata,
  desktopContentMetadata,
  prefix,
  noLink,
  limit = 2,
}: Props) {
  const desktopAuthors = [
    {
      id: desktopContentMetadata.authorId,
      name: desktopContentMetadata.authorName,
    },
    ...(desktopContentMetadata?.otherAuthors || []),
  ]?.filter(a => a.id) as Array<Author>;
  const mobileAuthors = [
    {
      id: mobileContentMetadata.authorId,
      name: mobileContentMetadata.authorName,
    },
    ...(mobileContentMetadata?.otherAuthors || []),
  ]?.filter(a => a.id) as Array<Author>;

  const hasMobileMoreAuthor = mobileAuthors.length > limit;
  const hasDesktopMoreAuthor = desktopAuthors.length > limit;

  return (
    <>
      {/* Main Mobile Author */}
      <div className="inline lg:hidden">
        {mobileAuthors.length && prefix ? prefix : null}
        {mobileAuthors?.slice(0, limit)?.map((author, index) => {
          const hasMoreAuthor = Boolean(
            mobileAuthors.length > 1 && index < limit - 1,
          );

          return (
            <AuthorRow
              key={author?.id}
              {...author}
              hasMoreAuthor={hasMoreAuthor}
              noLink={noLink}
            />
          );
        })}
        {hasMobileMoreAuthor ? (
          <span className="inline lg:hidden">, ...</span>
        ) : null}
      </div>

      {/* Main Desktop Author */}
      <div className="hidden lg:inline">
        {desktopAuthors.length && prefix ? prefix : null}
        {desktopAuthors?.slice(0, limit)?.map((author, index) => {
          const hasMoreAuthor = Boolean(
            desktopAuthors.length > 1 && index < limit - 1,
          );

          return (
            <AuthorRow
              key={author?.id}
              {...author}
              hasMoreAuthor={hasMoreAuthor}
              noLink={noLink}
            />
          );
        })}
        {hasDesktopMoreAuthor ? <span>, ...</span> : null}
      </div>
    </>
  );
}

export function PlatformAuthorName({
  mobileContentMetadata,
  desktopContentMetadata,
  prefix,
  noLink,
  limit,
}: Props) {
  if (!mobileContentMetadata.authorName) return '';

  return (
    <RenderAuthor
      noLink={noLink}
      mobileContentMetadata={mobileContentMetadata}
      desktopContentMetadata={desktopContentMetadata}
      prefix={prefix}
      limit={limit}
    />
  );
}
