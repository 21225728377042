import { ContentMetadata, LiveStream, SelectedMedia } from '@/lib/model';
import React from 'react';
import LiveTvVideoPlayer from '../../media/LiveTvVideoPlayer';
import videojs from 'video.js';

type PlayerOptions = typeof videojs.options;
type Props = {
  data?: ContentMetadata;
  [k: string]: any;
};

export const LiveStreamBox = ({ data, ...props }: Props) => {
  const liveStream = data?.liveStream as LiveStream;
  const options = () => {
    const url =
      typeof data?.liveStream === 'string'
        ? data.liveStream
        : liveStream?.streamUrl;
    const media = data?.medias?.[0]?.media as SelectedMedia;
    return {
      sources: [
        {
          src: url,
          type: liveStream?.type === 'tv' ? 'application/x-mpegURL' : undefined,
        },
      ],
      controls: true,
      poster: media?.media?.url,
      autoplay: true,
      muted: true,
      bigPlayButton: false,
    } as PlayerOptions;
  };

  return (
    <LiveTvVideoPlayer
      options={options()}
      title={data?.title}
      liveStream={liveStream}
      description={data?.description ?? ''}
      redirectUrl={props.href}
      showMiniPlayer={true}
      {...props}
    />
  );
};
