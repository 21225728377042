import { ContentMetadata, SelectedMedia } from '@/lib/model';
import React from 'react';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/app/components/carousel';
import { ImageProps } from 'next/image';
import { VideoImageBox } from '@/app/components/cards/common';
import { cn } from '@/lib/utils';

type Props = Partial<ImageProps> & {
  data: SelectedMedia[];
  contentMetadata: ContentMetadata;
  [k: string]: any;
};
export const MediaCarouselBox = ({ data, ...props }: Props) => {
  return (
    <>
      <Carousel>
        <CarouselContent
          className={cn(
            'gap-bv-6 grid grid-cols-[8px_73.5%_73.5%_73.5%_8px]',
            'sm:grid-cols-[38.5%_38.5%_38.5%_8px]',
            'md:grid-cols-3',
          )}
          withSidePadding
        >
          {data?.map((item, index) => {
            return (
              <CarouselItem
                key={`media-carousel-${index}`}
                className={props.className}
              >
                <VideoImageBox item={item} {...props} />
              </CarouselItem>
            );
          })}
        </CarouselContent>
        <CarouselPrevious className="absolute left-1/2 top-auto bottom-4 -translate-x-full text-white hidden md:block" />
        <CarouselNext className="absolute left-1/2 top-auto bottom-4 translate-x-1/2 text-white hidden md:block" />
      </Carousel>
    </>
  );
};
