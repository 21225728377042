import { ContentMetadata } from '@/lib/model';
import { cn } from '@/lib/utils';
import React from 'react';

type Props = {
  mobileContentMetadata: ContentMetadata;
  desktopContentMetadata: ContentMetadata;
  className?: string;
};

export function PlatformDescription({
  mobileContentMetadata,
  desktopContentMetadata,
  className,
}: Props) {
  return (
    <>
      <span className={cn('inline lg:hidden', className)}>
        {mobileContentMetadata?.description}
      </span>
      <span className={cn('hidden lg:inline', className)}>
        {desktopContentMetadata?.description}
      </span>
    </>
  );
}
