import { useTranslations } from 'next-intl';
import Link from 'next/link';

export function AdsTypeSecondWrapper() {
  const t = useTranslations();
  return (
    <div className="bg-[#950990] w-full py-16 flex flex-row justify-center">
      <Link
        href={'/'}
        className="main-sized-wrapper rounded-2xl no-underline article-hover-effect"
      >
        <div
          className="w-full h-[650px] relative bg-cover bg-no-repeat shadow-2xl overflow-hidden rounded-3xl"
          style={{
            backgroundImage: `url('/webp/ads-second-background.webp')`,
          }}
        >
          <div
            className="w-full h-[650px] mix-blend-multiply"
            style={{
              background:
                'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%)',
            }}
          ></div>

          <div className="absolute top-16 left-16 w-1/2">
            <div className="font-notoserif text-5xl font-bold leading-15 text-white">
              {t('secondMobileAppDesc')}
            </div>
          </div>

          <div className="absolute bottom-8 left-16">
            <img src="/svg/ads-label.svg" alt="ads" />
          </div>

          <div className="absolute right-16 bottom-8">
            <div className="flex flex-col gap-3">
              <img
                className="w-32 h-auto"
                src="/svg/label-logo.svg"
                alt="ads"
              />

              <div className="font-sf text-4xl font-extrabold leading-normal text-[#827576]">
                {t('secondMobileAppDate')}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
