import { ContentMetadata } from '@/lib/model';
import { MediaBox } from '@/app/components/cards/common';
import { cn } from '@/lib/utils';

type Props = {
  mobileContentMetadata: ContentMetadata;
  desktopContentMetadata: ContentMetadata;
  containerClassName?: string;
  [k: string]: any;
};

export function PlatformMediaBox({
  mobileContentMetadata,
  desktopContentMetadata,
  containerClassName,
  ...props
}: Props) {
  return (
    <>
      <div className={cn('block lg:hidden h-full', containerClassName)}>
        <MediaBox
          contentMetadata={mobileContentMetadata}
          {...props}
          autoplay={false}
        />
      </div>
      <div className={cn('hidden lg:block h-full', containerClassName)}>
        <MediaBox contentMetadata={desktopContentMetadata} {...props} />
      </div>
    </>
  );
}
