import { useTranslations } from 'next-intl';
import Image from 'next/image';
import background from '../../../../../public/image/ads-background.jpeg';

export function AdsTypeFirstWrapper() {
  const t = useTranslations();
  return (
    <section className="w-full relative flex flex-row justify-center">
      <Image alt="ads-background" fill src={background} quality={90} />

      {/* Desktop Version */}
      <div className="main-sized-wrapper relative z-1 hidden lg:block">
        <div className="py-8 w-full flex flex-row gap-16">
          <div className="w-3/12 flex flex-row relative">
            <img
              className="absolute bottom-4 left-24"
              src="/png/ads-first.png"
              alt="phone"
            />
            <img className="" src="/png/ads-second.png" alt="phone" />
          </div>

          <div className="w-4/12 flex flex-col gap-9 my-auto">
            <div className="flex flex-col gap-4">
              <img className="w-fit" src="/svg/ads-logo.svg" alt="logo" />

              <div className="font-notoserif text-white text-xl">
                {t('mobileAppDesc')}
              </div>
            </div>

            <div className="rounded bg-white px-4 py-2 w-fit text-[#00606a] font-notoserif text-base font-medium cursor-pointer first-letter:uppercase">
              {t('downloadNow')}
            </div>
          </div>

          <div className="w-5/12 flex flex-col gap-3 justify-center">
            <div className="font-notoserif text-white text-xl font-bold uppercase text-center">
              {t('availableOn')}
            </div>

            <div className="flex flex-row gap-9 justify-center">
              <img className="w-fit" src="/png/ads-apple.png" alt="ads" />

              <img className="w-fit" src="/png/ads-google.png" alt="ads" />
            </div>
          </div>
        </div>
      </div>

      {/* Tablet Version */}
      <div className="relative z-1 px-0 hidden md:block lg:hidden">
        <div className="py-8 w-full flex flex-row relative">
          <div className="w-1/3 flex flex-row relative">
            <img
              className="absolute bottom-4 left-24"
              src="/png/ads-first.png"
              alt="phone"
            />
            <img className="" src="/png/ads-second.png" alt="phone" />
          </div>

          <div className="w-2/3 flex flex-col gap-9 my-auto">
            <div className="flex flex-col gap-4">
              <img className="w-fit" src="/svg/ads-logo.svg" alt="logo" />

              <div className="font-notoserif text-white text-xl">
                {t('mobileAppDesc')}
              </div>
            </div>

            <div className="rounded bg-white px-4 py-2 w-fit text-[#00606a] font-notoserif text-base font-medium cursor-pointer first-letter:uppercase">
              {t('downloadNow')}
            </div>
          </div>

          <div className="flex flex-col gap-3 justify-center absolute right-5 bottom-5">
            <div className="font-notoserif text-white text-xl font-bold uppercase text-center">
              {t('availableOn')}
            </div>

            <div className="flex flex-row gap-2 justify-center">
              <img className="w-fit" src="/png/ads-apple.png" alt="ads" />

              <img className="w-fit" src="/png/ads-google.png" alt="ads" />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Version */}
      <div className="relative z-1 px-5 block md:hidden">
        <div className="py-10 w-full flex flex-col gap-8">
          <div className="flex flex-row relative">
            <div className="flex flex-col gap-4 md:gap-9 my-auto max-w-60">
              <div className="flex flex-col gap-8">
                <img className="w-fit" src="/svg/ads-logo.svg" alt="logo" />

                <div className="font-notoserif text-white text-base md:text-xl">
                  {t('mobileAppDesc')}
                </div>
              </div>

              <div className="rounded bg-white px-4 py-2 w-fit text-[#00606a] font-notoserif text-base font-medium cursor-pointer first-letter:uppercase">
                {t('downloadNow')}
              </div>
            </div>

            <div className="absolute end-0 bottom-0">
              <div className="flex flex-row relative">
                <img className="w-20" src="/png/ads-first.png" alt="phone" />
                <img
                  className="w-20 end-12 top-3 absolute"
                  src="/png/ads-second.png"
                  alt="phone"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2 md:gap-3">
            <div className="font-notoserif text-white text-base md:text-xl font-bold uppercase">
              {t('availableOn')}
            </div>

            <div className="flex flex-row gap-2">
              <img className="w-fit" src="/png/ads-apple.png" alt="ads" />

              <img className="w-fit" src="/png/ads-google.png" alt="ads" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
