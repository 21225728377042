import { Content, ContentMetadata, SelectedMedia } from '@/lib/model';

export function isVideoContentType(content: Content) {
  return content?.type === 'video';
}

export function isAudioContentType(content: Content) {
  return content?.type === 'audio';
}

export function isVideoContentMetadataType(contentMetadata: ContentMetadata) {
  return contentMetadata?.medias?.find(
    m => (m.media as SelectedMedia)?.media?.type === 'VIDEO',
  );
}

export function isAudioContentMetadataType(contentMetadata: ContentMetadata) {
  return contentMetadata?.medias?.find(
    m => (m.media as SelectedMedia)?.media?.type === 'AUDIO',
  );
}
