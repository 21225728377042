'use client';

import { useGetChannelPref } from '@/providers/ChannelProvider';
import Link from 'next/link';
import { ComponentProps } from 'react';
import { getArticleLink, getAudioLink, getVideoLink } from './link-utils';
import { ContentMetadata } from '@/lib/model';
import {
  isAudioContentMetadataType,
  isVideoContentMetadataType,
} from '@/utils/contentType/contentType';
import { cn } from '@/lib/utils';

interface MediaLinkProps
  extends Omit<ComponentProps<typeof Link>, 'href' | 'content'> {
  contentMetadata?: ContentMetadata;
}

interface Props extends Omit<MediaLinkProps, 'content'> {
  mobileContentMetadata?: ContentMetadata;
  desktopContentMetadata?: ContentMetadata;
  containerClassName?: string;
}

const MediaLink = ({
  contentMetadata: contentMetadata,
  ...props
}: MediaLinkProps) => {
  const channelPref = useGetChannelPref();

  if (!contentMetadata?.slug) {
    return (
      <div style={props.style} className={props.className}>
        {props.children}
      </div>
    );
  }

  const href = isAudioContentMetadataType(contentMetadata)
    ? getAudioLink(channelPref, contentMetadata.slug as string)
    : isVideoContentMetadataType(contentMetadata)
      ? getVideoLink(channelPref, contentMetadata.slug as string)
      : getArticleLink(channelPref, contentMetadata.slug as string);
  return <Link {...props} href={href} />;
};

export const ContentLink = ({
  mobileContentMetadata,
  desktopContentMetadata,
  className,
  containerClassName,
  ...props
}: Props) => {
  return (
    <>
      <div className={cn('block lg:hidden', containerClassName)}>
        <MediaLink
          className={className}
          {...props}
          contentMetadata={mobileContentMetadata}
        />
      </div>
      <div className={cn('hidden lg:block', containerClassName)}>
        <MediaLink
          className={className}
          {...props}
          contentMetadata={desktopContentMetadata}
        />
      </div>
    </>
  );
};
