export const formatDuration = (seconds: number | string) => {
  if (!seconds || isNaN(+seconds)) return '';
  const hrs = Math.floor(+seconds / 3600);
  const mins = Math.floor((+seconds % 3600) / 60);
  const secs = Math.floor(+seconds % 60);

  return [
    hrs > 0 ? String(hrs).padStart(2, '0') : null,
    String(mins).padStart(2, '0'),
    String(secs).padStart(2, '0'),
  ]
    .filter(Boolean)
    .join(':');
};

export const durationToSeconds = (duration?: string | null) => {
  if (!duration) return;
  const parts = duration.split(':').map(Number);
  let seconds = 0;

  if (parts.length === 3) {
    // hh:mm:ss
    seconds += parts[0] * 3600; // hours to seconds
    seconds += parts[1] * 60; // minutes to seconds
    seconds += parts[2]; // seconds
  } else if (parts.length === 2) {
    // mm:ss
    seconds += parts[0] * 60; // minutes to seconds
    seconds += parts[1]; // seconds
  } else if (parts.length === 1) {
    // ss
    seconds += parts[0]; // seconds
  }

  return seconds;
};
