import { ContentMetadata, SelectedMedia } from '@/lib/model';
import { ImageProps } from 'next/image';
import { VideoPlayer } from '@/app/components/pages/home';
import { cn } from '@/lib/utils';
import { ImageBox } from '@/app/components/cards/common/ImageBox';
import { LinkWithChannelLink } from '@/app/components/link/LinkWithChannel';
import { VideoLink } from '../../link/VideoLink';
import { AudioLink } from '../../link/AudioLink';
import { ArticleLink } from '../../link/ArticleLink';

type Props = Partial<ImageProps> & {
  item: SelectedMedia;
  playVideo?: boolean;
  contentMetadata: ContentMetadata;
  [k: string]: any;
  linkClassName?: string;
  playerClassName?: string;
  viewInDetailPage?: boolean;
};
export const VideoImageBox = ({
  item,
  playVideo = true,
  linkClassName,
  contentMetadata,
  autoplay,
  playerClassName,
  viewInDetailPage,
  hidePlaceholder,
  isLite,
  ...props
}: Props) => {
  const isVideo = item?.media?.type === 'VIDEO';
  const isAudio = item?.media?.type === 'AUDIO';

  if (!item) return null;
  if (viewInDetailPage && contentMetadata.slug) {
    return (
      <>
        {isVideo ? (
          <VideoLink slug={contentMetadata.slug as string}>
            <VideoPlayer
              contentMetadata={contentMetadata}
              media={item}
              isVideo={isVideo}
              isAudio={isAudio}
              autoplay={autoplay}
              playerClassName={playerClassName}
              viewInDetailPage={viewInDetailPage}
              isLite={isLite}
              hidePlaceholder={hidePlaceholder}
              {...props}
            />
          </VideoLink>
        ) : isAudio ? (
          <AudioLink slug={contentMetadata.slug as string}>
            <VideoPlayer
              contentMetadata={contentMetadata}
              media={item}
              isVideo={isVideo}
              isAudio={isAudio}
              autoplay={autoplay}
              playerClassName={playerClassName}
              viewInDetailPage={viewInDetailPage}
              hidePlaceholder={hidePlaceholder}
              isLite={isLite}
              {...props}
            />
          </AudioLink>
        ) : (
          <ArticleLink
            articleSlug={contentMetadata.slug as string}
            className={cn(linkClassName, 'relative')}
          >
            <ImageBox item={item} {...props} />
          </ArticleLink>
        )}
      </>
    );
  }

  return (
    <>
      {(isVideo || isAudio) && playVideo ? (
        <VideoPlayer
          contentMetadata={contentMetadata}
          media={item}
          isVideo={isVideo}
          isAudio={isAudio}
          autoplay={autoplay}
          playerClassName={playerClassName}
          viewInDetailPage={viewInDetailPage}
          isLite={isLite}
          hidePlaceholder={hidePlaceholder}
          {...props}
        />
      ) : props.href ? (
        <LinkWithChannelLink
          href={props.href ?? ''}
          className={cn(linkClassName, 'relative')}
        >
          <ImageBox item={item} {...props} />
        </LinkWithChannelLink>
      ) : (
        <div className={cn(linkClassName, 'relative')}>
          <ImageBox item={item} {...props} />
        </div>
      )}
    </>
  );
};
