import { ContentMetadata } from '@/lib/model';
import { getAuthorLinkWithId } from '@/utils/links/author';
import Image, { ImageProps } from 'next/image';
import { LinkWithChannelLink } from '../../link/LinkWithChannel';

type Props = {
  mobileContentMetadata: ContentMetadata;
  desktopContentMetadata: ContentMetadata;
} & Partial<ImageProps>;

export function PlatformAuthorPicture({
  mobileContentMetadata,
  desktopContentMetadata,
  ...props
}: Props) {
  const mobilePictureUrl =
    (typeof mobileContentMetadata.authorAvatarURL === 'string'
      ? mobileContentMetadata.authorAvatarURL
      : mobileContentMetadata.authorAvatarURL?.url) ?? '';
  const desktopPictureUrl =
    (typeof desktopContentMetadata.authorAvatarURL === 'string'
      ? desktopContentMetadata.authorAvatarURL
      : desktopContentMetadata.authorAvatarURL?.url) ?? '';

  return (
    (mobilePictureUrl || desktopPictureUrl) && (
      <LinkWithChannelLink
        href={getAuthorLinkWithId(mobileContentMetadata?.authorId || '')}
      >
        <span className={'inline lg:hidden'}>
          <Image
            src={mobilePictureUrl}
            alt={mobilePictureUrl}
            quality={60}
            {...props}
          />
        </span>

        <span className={'hidden lg:inline'}>
          <Image
            src={desktopPictureUrl}
            alt={desktopPictureUrl}
            quality={60}
            {...props}
          />
        </span>
      </LinkWithChannelLink>
    )
  );
}
