'use client';

import { useEffect, useMemo, useRef, useState } from 'react';
import { Content, SelectedMedia } from '@/lib/model';
import {
  getContentDurationString,
  getContentMetadata,
  getMediaIconType,
  getOptimizedImageFromCoverImage,
} from '@/common/utils';
import { PlatformTitle } from '@/app/components/cards/common';
import clsx from 'clsx';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import { getVidoeSrc } from '@/common/getVideoSrc';
import { PlayWithTimer } from '../../media/PlayWithTimer/PlayWithTimer';

type Props = {
  content?: Content;
  isHover?: boolean;
};
const initialOptions = {
  loop: true,
  controls: false,
  fluid: true,
  muted: false,
  bigPlayButton: false,
  width: 333,
  playsinline: true,
  preload: 'none',
} as typeof videojs.options;

export function VideoCard({ content, isHover = false }: Props) {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<Player | null>(null);
  const { isVideo, isAudio } = getMediaIconType(content);

  const { mobileContentMetadata, desktopContentMetadata } = useMemo(
    () => getContentMetadata(content),
    [content],
  );

  const contentMetadata = useMemo(() => {
    return typeof window !== 'undefined' && window.outerWidth < 768
      ? mobileContentMetadata
      : desktopContentMetadata;
  }, [desktopContentMetadata, mobileContentMetadata]);

  const media = useMemo(() => {
    return (
      (contentMetadata.medias?.find(m => {
        return typeof m.media === 'string'
          ? /mp4$|mkv|webm|ogv/gi.test(m.media)
          : (m.media as SelectedMedia)?.media?.type === 'VIDEO';
      })?.media as SelectedMedia) ?? (content?.media as SelectedMedia)
    );
  }, [content?.media, contentMetadata.medias]);

  const image = getOptimizedImageFromCoverImage(
    content?.coverImage,
    9 as number,
    16 as number,
  );

  const options = useMemo(() => {
    if (!media) return null;

    return {
      ...initialOptions,
      sources: [
        {
          src: getVidoeSrc(media),
        },
      ],
      poster: image,
    };
  }, [media]);

  const [duration, setDuration] = useState(
    getContentDurationString(content?.duration as string),
  );

  useEffect(() => {
    if (!playerRef.current && options) {
      const videoElement = document.createElement('video-js');
      videoRef?.current?.appendChild(videoElement);
      playerRef.current = videojs(videoElement, options, () => {});
      if (!contentMetadata.duration) {
        playerRef.current.preload('metadata');
      }
      playerRef.current.on('loadedmetadata', () => {
        if (!contentMetadata.duration) {
          setDuration(getContentDurationString(playerRef.current?.duration()));
        }
      });
    } else {
      const player = playerRef.current;
      player?.src(options.sources);
    }
  }, [options, videoRef, contentMetadata.duration]);

  useEffect(() => {
    const player = playerRef.current;
    if (isHover && player?.paused()) {
      player?.play()?.catch(() => {
        // on video.js play error due to element unmount or pause before play
      });
    } else {
      player?.pause();
    }
  }, [isHover]);

  return (
    <div
      className="w-full aspect-[302/526] rounded-custom relative cursor-pointer video-card-wrapper overflow-hidden"
      style={{ background: `url('${image}')`, backgroundSize: 'cover' }}
    >
      <div className={'h-full'} data-vjs-player={true}>
        <div className={'h-full'} ref={videoRef} />
      </div>
      <div className="w-full h-full absolute bottom-0 bg-gradient-to-b -from-[0%] to-[100%] from-[#00000000] to-[#000000E5] rounded-custom overflow-hidden" />
      <div
        className={clsx(
          'absolute bottom-4 left-4 right-4 flex flex-col gap-4',
          {
            'opacity-0 transition-opacity duration-300 ease-linear': isHover,
          },
        )}
      >
        <div className="font-playfair text-xl font-semibold text-white leading-custom_5">
          <PlatformTitle
            mobileContentMetadata={mobileContentMetadata}
            desktopContentMetadata={desktopContentMetadata}
          />
        </div>

        {(isVideo || isAudio) && (
          <PlayWithTimer content={content} duration={duration} />
        )}
      </div>
    </div>
  );
}
