'use client';

import { Content, SelectedMedia } from '@/lib/model';

import { formatDuration } from '@/utils/time/formatDuration';
import { forwardRef, useImperativeHandle } from 'react';
import { MediaIcon, RoundedChevronRightIcon } from '@/app/components/icons';
import clsx from 'clsx';
import { getMediaIconType } from '@/common/utils';
import { PauseIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useAudioPlayerWithCountDown } from './useAudioPlayerWithCountDown';
import { LinkWithChannelLink } from '../link/LinkWithChannel';
import { AnimateSpinIcon } from '../icons/AnimateSpinIcon';

export type PlayerWithTimerRef = {
  togglePlay: () => void;
  play: () => void;
  stop: () => void;
};

interface Props {
  content: Content;
  image: string;
  hideDetailPageArrow?: boolean;
}

export const AudioPlayerWithTimer = forwardRef<PlayerWithTimerRef, Props>(
  ({ content, hideDetailPageArrow }, ref) => {
    const src = (content.media as SelectedMedia)?.media?.url;

    const { isVideo, isAudio } = getMediaIconType(content);

    const {
      onEnded,
      onLoadedMetadata,
      timeLeft,
      togglePause,
      isPlaying,
      audioRef,
      play,
      pause,
      isLoading,
    } = useAudioPlayerWithCountDown(content?.duration);

    useImperativeHandle(ref, () => {
      return {
        play,
        stop: pause,
        togglePlay: togglePause,
      };
    }, [togglePause, play, pause]);

    return (
      <div className="flex flex-row justify-between">
        <div
          onClick={() => {
            togglePause();
          }}
          className="flex gap-2 relative w-full"
        >
          {isLoading ? (
            <div className="flex z-10 items-center justify-center">
              <AnimateSpinIcon
                className="z-10 text-black m-auto"
                color="white"
              />
            </div>
          ) : (
            <div
              className={cn(
                'w-7 h-7 rounded-full flex items-center justify-center',
                {
                  'bg-white': isPlaying,
                  'bg-[#FFFFFF4D]': !isPlaying,
                },
              )}
            >
              {isPlaying ? (
                <PauseIcon fill="#000" stroke="transparent" size={18} />
              ) : (
                <MediaIcon
                  content={content}
                  className={clsx('text-white', {
                    'w-5 h-5': isVideo,
                    'w-4 h-4': isAudio,
                  })}
                />
              )}
            </div>
          )}
          {src ? (
            <audio
              id={content?.id}
              ref={audioRef}
              src={src}
              onCanPlayThrough={() => {
                onLoadedMetadata();
                play();
              }}
              className="h-0"
              preload="none"
              {...(!content.duration && {
                onLoadedMetadata: onLoadedMetadata,
              })}
              onEnded={onEnded}
            />
          ) : null}

          <div className="font-sf text-sm font-medium text-grey-2 uppercase my-auto">
            {formatDuration(timeLeft)}
          </div>
        </div>
        {!hideDetailPageArrow && (
          <LinkWithChannelLink
            href={`/audio/${content?.slug}`}
            className="flex relative z-10 rtl:rotate-180"
          >
            <RoundedChevronRightIcon color="white" />
          </LinkWithChannelLink>
        )}
      </div>
    );
  },
);
