import Image, { ImageProps } from 'next/image';
import { SelectedMedia } from '@/lib/model';
import { COMMON_RESPONSIVE_IMAGE_SIZES } from '@/utils/image/reponsiveSizes';
import { toBase64, mediaShimmer } from '../../media/mediaShimmer';
import { cn } from '@/lib/utils';
import { getOptimizedImageFromCoverImage } from '@/common/utils';
import { ImagePlaceholder } from '../../media/ImagePlaceholder';

type Props = Partial<ImageProps> & {
  item: SelectedMedia;
  [k: string]: any;
};
export const ImageBox = ({
  item,
  className,
  width,
  height,
  style,
  ...props
}: Props) => {
  if (
    !(
      item?.media?.optimizedThumbnailUrl?.startsWith('https://') ||
      item?.media?.url?.startsWith('https://')
    )
  ) {
    return (
      <ImagePlaceholder
        width={width}
        height={height}
        className={className}
        style={style}
        {...props}
      />
    );
  }
  return (
    <>
      <Image
        alt={item?.media.caption ?? item?.media.url ?? ''}
        quality={80}
        loading="lazy"
        sizes={COMMON_RESPONSIVE_IMAGE_SIZES}
        {...props}
        {...(width && height
          ? { style: { ...style, aspectRatio: `${width} / ${height}` } }
          : { style })}
        className={cn('object-cover rounded', className)}
        src={
          getOptimizedImageFromCoverImage(
            item,
            width as number,
            height as number,
          ) as string
        }
        width={width}
        height={height}
        placeholder={`data:image/svg+xml;base64,${toBase64(mediaShimmer({ width, height }))}`}
      />
    </>
  );
};
