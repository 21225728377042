import { ContentMetadata, LiveStream, SelectedMedia } from '@/lib/model';
import { ImageProps } from 'next/image';
import React from 'react';
import {
  LiveStreamBox,
  MediaCarouselBox,
  VideoImageBox,
} from '@/app/components/cards/common';
import { LinkWithChannelLink } from '../../link/LinkWithChannel';
import { cn } from '@/lib/utils';
import { ImagePlaceholder } from '../../media/ImagePlaceholder';

type Props = Partial<ImageProps> & {
  contentMetadata: ContentMetadata;
  [k: string]: any; // TODO: Live stream Carousel extra props types
};

export const MediaBox = ({ contentMetadata, ...props }: Props) => {
  if (contentMetadata.liveStream) {
    if (typeof contentMetadata.liveStream === 'string') {
      return (
        <LiveStreamBox
          data={{ streamUrl: contentMetadata.liveStream } as LiveStream}
          {...props}
        />
      );
    }
    return <LiveStreamBox data={contentMetadata} {...props} />;
  }
  const medias =
    contentMetadata.medias
      ?.filter(media => !!media)
      .map(media => {
        if (media && typeof media.media === 'string') {
          return { media: { url: media.media } } as SelectedMedia;
        }
        return media.media as SelectedMedia;
      }) ?? [];
  if (medias.length > 1) {
    return (
      <MediaCarouselBox
        contentMetadata={contentMetadata}
        data={medias}
        {...props}
      />
    );
  } else if (medias.length > 0) {
    const media = medias[0];
    return (
      <VideoImageBox
        contentMetadata={contentMetadata}
        item={media}
        {...props}
      />
    );
  }
  if (props.href && !(props.href as string).endsWith('/undefined')) {
    return (
      <LinkWithChannelLink href={props.href ?? ''}>
        <ImagePlaceholder
          width={props.width || 400}
          height={props.height || 400}
          alt={'place holder'}
          src={'/png/placeholder.png'}
          className={cn('object-cover', props.className)}
          quality={80}
        />
      </LinkWithChannelLink>
    );
  }
  return (
    <ImagePlaceholder
      width={props.width || 400}
      height={props.height || 400}
      alt={'place holder'}
      src={'/png/placeholder.png'}
      className={cn('object-cover', props.className)}
      quality={80}
    />
  );
};
