import { MediaIcon } from '@/app/components/icons';
import { Content } from '@/lib/model';
import { cn } from '@/lib/utils';

interface Props {
  content?: Content;
  duration?: string;
}

export const PlayWithTimer = ({ content, duration }: Props) => {
  if (!content?.subType) return null;

  return (
    <>
      <div className="flex gap-2">
        <div className="w-7 h-7 bg-[#FFFFFF4D] rounded-full relative">
          <MediaIcon
            content={content}
            className={cn(
              'text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
            )}
          />
        </div>

        <div className="text-white">{duration}</div>
      </div>
    </>
  );
};
