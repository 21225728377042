import { cn } from '@/lib/utils';
import Image, { ImageProps } from 'next/image';

type Props = Partial<ImageProps>;

export const ImagePlaceholder = ({
  width,
  height,
  style,
  className,
  ...props
}: Props) => {
  return (
    <Image
      {...props}
      {...(width && height
        ? { style: { ...style, aspectRatio: `${width} / ${height}` } }
        : { style })}
      alt="place holder"
      src="/png/placeholder.png"
      className={cn('object-cover rounded', className)}
      width={width}
      height={height}
    />
  );
};
