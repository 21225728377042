import { durationToSeconds } from '@/utils/time/formatDuration';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export function useAudioPlayerWithCountDown(duration?: string | null) {
  const [metadataDuration, setMetadataDuration] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const onLoadedMetadata = useCallback(() => {
    if (audioRef.current?.duration && !wholeAudioDurationInSec.current) {
      setMetadataDuration(audioRef.current.duration);
      setTimeLeft(audioRef.current.duration);
    }
  }, []);
  const durationInSec = useMemo(
    () => durationToSeconds(duration) || metadataDuration || 0,
    [metadataDuration, duration],
  );

  /**
   * audio duration
   * 1- suppose to be sent from cms
   * 2- If not, app try to calculate it based on metadata
   * 3- If non above works, when Audio is played this value is extracted and assigned here
   */
  const wholeAudioDurationInSec = useRef(durationInSec);

  const [timeLeft, setTimeLeft] = useState(durationInSec);

  const [isPlaying, setIsPlaying] = useState(false);

  const pause = useCallback(() => {
    audioRef.current?.pause();
  }, []);

  const play = useCallback(() => {
    if (!timeLeft) {
      const dur = audioRef.current?.duration || 0;
      wholeAudioDurationInSec.current = dur;
      setTimeLeft(dur);
    }

    audioRef.current?.play()?.catch(e => {
      console.error(e);
      // on video.js play error due to element unmount or pause before play
    });
  }, [timeLeft]);

  const togglePause = useCallback(() => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  }, [play, pause, isPlaying]);

  useEffect(() => {
    if (!isPlaying || isLoading) return;

    const id = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          // Stop at zero
          clearInterval(id);
          audioRef.current?.pause();
          setIsPlaying(false); // Timer stops when reaching zero
          return 0;
        }
        return (
          wholeAudioDurationInSec.current -
          (audioRef.current?.currentTime || prevTime - 1)
        );
      });
    }, 1000);

    intervalId.current = id;

    return () => clearInterval(id);
  }, [isPlaying, isLoading]);

  useEffect(() => {
    if (!audioRef.current) return;

    const playHandler = () => {
      setIsPlaying(true);
    };
    const pauseHandler = () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      setIsPlaying(false);
    };

    const waitingHandler = () => {
      setLoading(true);
    };
    const loadedHandler = () => {
      setLoading(false);
    };

    audioRef?.current?.addEventListener('waiting', waitingHandler, true);
    audioRef?.current?.addEventListener('loadeddata', loadedHandler, true);
    audioRef?.current?.addEventListener('play', playHandler, true);
    audioRef?.current?.addEventListener('pause', pauseHandler, true);
    return () => {
      audioRef?.current?.removeEventListener('play', playHandler, true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      audioRef?.current?.removeEventListener('pause', pauseHandler, true);
      audioRef?.current?.removeEventListener('waiting', waitingHandler, true);
      audioRef?.current?.removeEventListener('loadeddata', loadedHandler, true);
    };
  }, []);

  const onEnded = useCallback(() => {
    pause();
    if (wholeAudioDurationInSec.current)
      setTimeLeft(wholeAudioDurationInSec.current);
  }, [pause]);

  return {
    onEnded,
    onLoadedMetadata,
    timeLeft,
    togglePause,
    isPlaying,
    audioRef,
    play,
    pause,
    isLoading,
    setLoading,
  };
}
